define("discourse/plugins/discourse-ai/discourse/admin/models/ai-persona", ["exports", "@glimmer/tracking", "discourse/lib/ajax", "discourse/models/rest"], function (_exports, _tracking, _ajax, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const ATTRIBUTES = ["name", "description", "commands", "system_prompt", "allowed_group_ids", "enabled", "system", "priority", "top_p", "temperature", "user_id", "mentionable", "default_llm", "user", "max_context_posts", "vision_enabled", "vision_max_pixels"];
  const SYSTEM_ATTRIBUTES = ["allowed_group_ids", "enabled", "system", "priority", "user_id", "mentionable", "default_llm", "user", "max_context_posts", "vision_enabled", "vision_max_pixels"];
  let CommandOption = (_class = class CommandOption {
    constructor() {
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
  class AiPersona extends _rest.default {
    // this code is here to convert the wire schema to easier to work with object
    // on the wire we pass in/out commands as an Array.
    // [[CommandName, {option1: value, option2: value}], CommandName2, CommandName3]
    // So we rework this into a "commands" property and nested commandOptions
    init(properties) {
      if (properties.commands) {
        properties.commands = properties.commands.map(command => {
          if (typeof command === "string") {
            return command;
          } else {
            let [commandId, options] = command;
            for (let optionId in options) {
              if (!options.hasOwnProperty(optionId)) {
                continue;
              }
              this.getCommandOption(commandId, optionId).value = options[optionId];
            }
            return commandId;
          }
        });
      }
      super.init(properties);
      this.commands = properties.commands;
    }
    async createUser() {
      const result = await (0, _ajax.ajax)(`/admin/plugins/discourse-ai/ai-personas/${this.id}/create-user.json`, {
        type: "POST"
      });
      this.user = result.user;
      this.user_id = this.user.id;
      return this.user;
    }
    getCommandOption(commandId, optionId) {
      this.commandOptions ||= {};
      this.commandOptions[commandId] ||= {};
      return this.commandOptions[commandId][optionId] ||= new CommandOption();
    }
    populateCommandOptions(attrs) {
      if (!attrs.commands) {
        return;
      }
      let commandsWithOptions = [];
      attrs.commands.forEach(commandId => {
        if (typeof commandId !== "string") {
          commandId = commandId[0];
        }
        if (this.commandOptions && this.commandOptions[commandId]) {
          let options = this.commandOptions[commandId];
          let optionsWithValues = {};
          for (let optionId in options) {
            if (!options.hasOwnProperty(optionId)) {
              continue;
            }
            let option = options[optionId];
            optionsWithValues[optionId] = option.value;
          }
          commandsWithOptions.push([commandId, optionsWithValues]);
        } else {
          commandsWithOptions.push(commandId);
        }
      });
      attrs.commands = commandsWithOptions;
    }
    updateProperties() {
      let attrs = this.system ? this.getProperties(SYSTEM_ATTRIBUTES) : this.getProperties(ATTRIBUTES);
      attrs.id = this.id;
      this.populateCommandOptions(attrs);
      return attrs;
    }
    createProperties() {
      let attrs = this.getProperties(ATTRIBUTES);
      this.populateCommandOptions(attrs);
      return attrs;
    }
    workingCopy() {
      return AiPersona.create(this.createProperties());
    }
  }
  _exports.default = AiPersona;
});